import { useCallback, useState } from 'react'

import { dateStringError, emailError, passwordError } from 'lib/utils/formValidationUtils'

// Updated version of utils/useForm. Should use this from here on out!!

export type InitialContext = {
    required?: boolean
    getError?: (value: any) => string | undefined
    type?: 'dateString' | 'email' | 'password'
}

export type FormContext = InitialContext & {
    isDirty?: boolean
    error?: string
    displayError?: string
}

type InitialFormContext<T> = Partial<Record<keyof T, InitialContext>> & {
    allRequired?: true
}

type FormContextDict<T> = Record<keyof T, FormContext>

type Return<T> = {
    formData: T
    formContext: FormContextDict<T>
    formError: string
    handleChange: (key: keyof T, val: any, sanitizeFn?: (val: any) => any) => void
    handleBlur: (key: keyof T) => void
    setFormError: (error: string) => void
    getFormError: () => string | undefined
    resetForm: (initialValues: T, initialFormContext?: InitialFormContext<T>) => void
}

export function useForm<T extends Record<string, any>>(
    initialValues: T,
    initialFormContext?: InitialFormContext<T>
): Return<T> {
    const [formError, setFormError] = useState('')
    const [formData, setFormData] = useState(initialValues)
    const [formContext, setFormContext] = useState(
        getInitialFormContext(initialValues, initialFormContext)
    )

    const resetForm = useCallback(
        (initialValues: T, initialFormContext?: InitialFormContext<T>) => {
            setFormData(initialValues)
            setFormContext(getInitialFormContext(initialValues, initialFormContext))
            setFormError('')
        },
        []
    )

    const handleChange = useCallback(
        (key: keyof T, val: any, sanitizeFn: (val: any) => any) => {
            const value = sanitizeFn ? sanitizeFn(val) : val

            setFormData((prevState) => ({ ...prevState, [key]: val }))

            setFormContext((prevState) => {
                const prevContext = prevState[key] ?? {}
                const errorFunc = getContextErrorFunc(prevContext)
                const error = errorFunc?.(value)
                return {
                    ...prevState,
                    [key]: {
                        ...prevContext,
                        error,
                        displayError: prevContext.isDirty ? error : undefined,
                    },
                }
            })
            setFormError('')
        },
        []
    )

    const handleBlur = useCallback((key: keyof T) => {
        setFormContext((prevState) => {
            prevState[key].isDirty = true
            prevState[key].displayError = prevState[key].error
            return prevState
        })
    }, [])

    const getFormError = useCallback(() => {
        const postValidationFormContext = { ...formContext }

        let hasError = false
        Object.entries<FormContext>(formContext).forEach(([key, context]) => {
            if (!context) return
            const getError = getContextErrorFunc(context)
            const value = formData[key]
            postValidationFormContext[key].isDirty = true
            const error = getError?.(value)
            postValidationFormContext[key].error = error
            postValidationFormContext[key].displayError = error
            if (error) hasError = true
        })

        const error = hasError ? 'Favor de revisar los errores.' : ''
        setFormContext(postValidationFormContext)
        setFormError(error)
        return error || undefined
    }, [formContext, formData])

    return {
        formData,
        formContext,
        formError,
        handleChange,
        handleBlur,
        setFormError,
        getFormError,
        resetForm,
    }
}

function getInitialFormContext<T extends Record<string, any>>(
    initialValues: T,
    initialFormContext: InitialFormContext<T> | undefined
): Record<keyof T, FormContext> {
    const newContext = {}
    const keys = Object.keys(initialValues)
    const allRequired = initialFormContext?.allRequired
    keys.forEach((key) => {
        newContext[key] = initialFormContext?.[key] || {}
        if (allRequired === true) newContext[key].required = true
    })
    return newContext as Record<keyof T, FormContext>
}

function getContextErrorFunc(
    context: FormContext
): ((val: any) => string | undefined) | undefined {
    if (context.getError) return context.getError
    if (context.type === 'dateString') return dateStringError
    if (context.type === 'email') return emailError
    if (context.type === 'password') return passwordError

    return context.required
        ? (value) => {
              if (typeof value === 'boolean' && value !== undefined) return

              return !value || (typeof value === 'string' && !value.trim())
                  ? 'Este campo es requerido.'
                  : undefined
          }
        : undefined
}
